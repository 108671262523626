import React from 'react';
import { Helmet } from 'react-helmet';


const AboutUs = () => {
  return (
    <div className="wrapper">
      <Helmet>
        <title>About Us - Ttoni | About us</title>
        <meta 
          name="description" 
          content="Learn more about Ttoni, a platform offering free, high-quality Artists tools and services." 
        />
      </Helmet>
      <div className="page-container">
        <div className="content">
          <h1 className="title">About Us</h1>
          
          <div className="text-section">
            <p>
              AudioConvert is a state-of-the-art online audio conversion platform, dedicated to providing free, high-quality audio format conversion and video-to-audio extraction services. Founded in 2024, our mission is to make professional-grade media conversion accessible to everyone.
            </p>
          </div>

          <div className="features-section">
            <h2 className="subtitle">Audio Conversion Features</h2>
            <ul className="features-list">
              <li>Browser-based conversion with no software installation required</li>
              <li>Support for multiple professional audio formats with maximum quality settings:
                <ul>
                  <li>MP3: Highest quality 320 kbps bitrate</li>
                  <li>FLAC: Lossless compression with maximum level 8 compression</li>
                  <li>OGG: Maximum quality setting (q10)</li>
                  <li>AAC: High-quality 320 kbps encoding</li>
                  <li>WAV: Uncompressed, original quality preservation</li>
                </ul>
              </li>
              <li>Zero quality loss in lossless formats (WAV, FLAC)</li>
              <li>Professional-grade encoding parameters for all formats</li>
            </ul>
          </div>

          <div className="features-section">
            <h2 className="subtitle">Video to Audio Extraction</h2>
            <ul className="features-list">
              <li>Extract high-quality audio from various video formats:
                <ul>
                  <li>MP4, WebM, MOV, AVI</li>
                  <li>MKV, WMV, MPEG, MPG</li>
                  <li>3GP, FLV, OGV</li>
                </ul>
              </li>
              <li>Professional-grade audio extraction with format-specific optimizations:
                <ul>
                  <li>MP3: High-fidelity 320 kbps extraction</li>
                  <li>FLAC: Lossless extraction with level 8 compression</li>
                  <li>OGG: Maximum quality (q10) for optimal clarity</li>
                  <li>AAC: Crystal-clear 320 kbps encoding</li>
                  <li>WAV: Bit-perfect audio extraction</li>
                </ul>
              </li>
              <li>Support for video files up to 500MB</li>
              <li>Maintains original audio quality from source video</li>
              <li>Optimized for various video sources:
                <ul>
                  <li>YouTube downloads</li>
                  <li>Screen recordings</li>
                  <li>Mobile video recordings</li>
                  <li>Professional video productions</li>
                </ul>
              </li>
            </ul>
          </div>

          <div className="features-section">
            <h2 className="subtitle">Platform Benefits</h2>
            <ul className="features-list">
              <li>Local file processing for enhanced privacy and security</li>
              <li>Batch conversion capabilities for multiple files</li>
              <li>Intuitive drag-and-drop interface</li>
              <li>Real-time conversion progress tracking</li>
              <li>Automatic file sanitization and validation</li>
            </ul>
          </div>

          <div className="text-section">
            <p>
              Our platform serves musicians, podcasters, video creators, content producers, and media enthusiasts worldwide, processing thousands of conversions daily. Whether you're converting between audio formats or extracting high-quality audio from videos, we maintain the highest standards of quality while ensuring a seamless user experience.
            </p>
          </div>
        </div>
      </div>

      <style jsx>{`
        .wrapper {
          min-height: 100vh;
          display: flex;
          flex-direction: column;
        }
        
        .page-container {
          flex: 1;
          box-sizing: border-box;
          width: 100%;
          background-color: white;
        }

        .content {
          box-sizing: border-box;
          max-width: 800px;
          margin: 0 auto;
          font-family: "Lexend", sans-serif;
          padding: 4.44vh 2.22vh;
        }

        .title {
          font-family: "DM Sans", sans-serif;
          font-size: 3.33vh;
          font-weight: bold;
          color: #1b1f26;
          margin-bottom: 2.22vh;
          text-align: center;
        }

        .subtitle {
          font-family: "DM Sans", sans-serif;
          font-size: 2.22vh;
          font-weight: bold;
          color: #1b1f26;
          margin: 2.22vh 0 1.11vh;
        }

        .text-section {
          margin-bottom: 2.22vh;
        }

        .text-section p {
          font-size: 1.67vh;
          line-height: 1.6;
          color: #666;
        }

        .features-section {
          margin: 2.22vh 0;
          padding: 2.22vh;
          background-color: #f8f9fa;
          border-radius: 0.83vh;
        }

        .features-list {
          list-style-type: disc;
          padding-left: 2.22vh;
          margin: 1.11vh 0;
        }

        .features-list li {
          font-size: 1.67vh;
          color: #666;
          margin: 0.83vh 0;
          line-height: 1.4;
        }

        .features-list li ul {
          margin-top: 0.83vh;
          margin-left: 1.67vh;
        }

        @media (max-width: 767px) {
          .content {
            padding: 3.33vh 1.67vh;
          }

          .title {
            font-size: 2.78vh;
          }

          .features-section {
            padding: 1.67vh;
          }
        }
      `}</style>
    </div>
  );
};

export default AboutUs;