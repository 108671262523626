import React from 'react';

const HowItWorks = () => {
  return (
    <div className="wrapper">
      <div className="page-container">
        <div className="content">
          <h1 className="title">How It Works</h1>

          <div className="text-section">
            <p>AudioConvert offers two main services: audio format conversion and video-to-audio extraction. Both follow a simple three-step process:</p>
          </div>

          <div className="section-container">
            <h2 className="section-title">Audio Format Conversion</h2>
            
            <div className="text-section">
              <h2 className="subtitle">1. Select Your Format</h2>
              <p>Choose your desired output format from our supported formats. Each format is optimized for specific use cases:</p>
              <ul className="features-list">
                <li><strong>MP3:</strong> Best for general use and music streaming (320 kbps)</li>
                <li><strong>WAV:</strong> Perfect for professional audio production (uncompressed)</li>
                <li><strong>OGG:</strong> Ideal for web streaming and gaming (q10 quality)</li>
                <li><strong>AAC:</strong> Optimized for Apple devices and digital broadcasting (320 kbps)</li>
                <li><strong>FLAC:</strong> Lossless compression for audiophiles (level 8 compression)</li>
              </ul>
            </div>

            <div className="text-section">
              <h2 className="subtitle">2. Upload Your Files</h2>
              <ul className="features-list">
                <li>Drag and drop your audio files directly into the converter</li>
                <li>Or click to browse and select files from your device</li>
                <li>Support for multiple file uploads</li>
                <li>Compatible with most input audio formats</li>
              </ul>
            </div>

            <div className="text-section">
              <h2 className="subtitle">3. Download Converted Files</h2>
              <ul className="features-list">
                <li>Automatic conversion starts immediately</li>
                <li>Choose to download individual files or all at once</li>
                <li>No registration required</li>
                <li>Files are processed locally for immediate results</li>
              </ul>
            </div>
          </div>

          <div className="section-container">
            <h2 className="section-title">Video to Audio Extraction</h2>
            
            <div className="text-section">
              <h2 className="subtitle">1. Choose Output Format</h2>
              <p>Select which audio format you want to extract your video's audio to. Supported video input formats include:</p>
              <ul className="features-list">
                <li>Common formats: MP4, WebM, MOV, AVI</li>
                <li>Professional formats: MKV, WMV, MPEG, MPG</li>
                <li>Mobile formats: 3GP, FLV, OGV</li>
                <li>Maximum video file size: 500MB</li>
              </ul>
            </div>

            <div className="text-section">
              <h2 className="subtitle">2. Upload Your Video</h2>
              <ul className="features-list">
                <li>Drag and drop video files into the converter</li>
                <li>Click to select videos from your device</li>
                <li>Support for batch video processing</li>
                <li>Automatic video format detection</li>
              </ul>
            </div>

            <div className="text-section">
              <h2 className="subtitle">3. Get Your Audio</h2>
              <ul className="features-list">
                <li>Audio extraction begins automatically</li>
                <li>Download individual tracks or all at once</li>
                <li>High-quality audio preservation</li>
                <li>Format-specific optimizations applied</li>
              </ul>
            </div>
          </div>

          <div className="text-section">
            <h2 className="subtitle">Advanced Features</h2>
            <ul className="features-list">
              <li>Batch processing for multiple files</li>
              <li>Automatic format detection</li>
              <li>High-quality conversion algorithms</li>
              <li>Browser-based processing for instant results</li>
              <li>Local file processing for enhanced privacy</li>
              <li>File validation and sanitization</li>
              <li>Progress tracking for all conversions</li>
            </ul>
          </div>
        </div>
      </div>

      <style jsx>{`
        .wrapper {
          min-height: 100vh;
          display: flex;
          flex-direction: column;
        }

        .page-container {
          flex: 1;
          box-sizing: border-box;
          width: 100%;
          background-color: white;
        }

        .content {
          box-sizing: border-box;
          max-width: 800px;
          margin: 0 auto;
          font-family: "Lexend", sans-serif;
          padding: 4.44vh 2.22vh;
        }

        .section-container {
          margin: 3.33vh 0;
          padding: 2.22vh;
          background-color: #f8f9fa;
          border-radius: 0.83vh;
        }

        .section-title {
          font-family: "DM Sans", sans-serif;
          font-size: 2.5vh;
          font-weight: bold;
          color: #1b1f26;
          margin-bottom: 2.22vh;
          padding-bottom: 1.11vh;
          border-bottom: 1px solid #e9ecef;
        }

        .title {
          font-family: "DM Sans", sans-serif;
          font-size: 3.33vh;
          font-weight: bold;
          color: #1b1f26;
          margin-bottom: 2.22vh;
          text-align: center;
        }

        .subtitle {
          font-family: "DM Sans", sans-serif;
          font-size: 2.22vh;
          font-weight: bold;
          color: #1b1f26;
          margin-top: 1.67vh;
        }

        .text-section {
          margin-bottom: 2.22vh;
        }

        .text-section p {
          font-size: 1.67vh;
          line-height: 1.6;
          color: #666;
        }

        .features-list {
          list-style-type: disc;
          padding-left: 2.22vh;
          margin: 1.11vh 0;
        }

        .features-list li {
          font-size: 1.67vh;
          color: #666;
          margin: 0.83vh 0;
          line-height: 1.4;
        }

        .features-list ul {
          margin-top: 0.56vh;
        }

        strong {
          color: #1b1f26;
          font-weight: 500;
        }

        @media (max-width: 767px) {
          .content {
            padding: 3.33vh 1.67vh;
          }

          .title {
            font-size: 2.78vh;
          }

          .section-container {
            padding: 1.67vh;
          }
        }
      `}</style>
    </div>
  );
};

export default HowItWorks;